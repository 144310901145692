import classNames from 'classnames';
import React from 'react';
import './_styles.scss';

export const BlockArrow = ({
  poke,
  direction,
  pokeSize,
  borderRadius = 0,
  forcedOffsetX,
  forcedOffsetY,
  className = {},
  secondaryPosition,
}) => {
  const {style} = poke;
  const {background, pointerColor, borderColor} = style ?? {};

  const [position, offsetX, offsetY] = direction?.split(';') || [];
  const OffsetXInt = parseInt(offsetX || 0, 10);
  const OffsetYInt = parseInt(offsetY || 0, 10);

  let adjustedOffsetX = Math.min(
    Math.abs(OffsetXInt),
    pokeSize?.width / 2 - borderRadius - 7
  );
  adjustedOffsetX =
    forcedOffsetX != null
      ? forcedOffsetX
      : OffsetXInt === 0
      ? 0
      : OffsetXInt < 0
      ? -adjustedOffsetX
      : adjustedOffsetX;

  let adjustedOffsetY = Math.min(
    Math.abs(OffsetYInt),
    pokeSize?.height / 2 - borderRadius - 7
  );
  adjustedOffsetY =
    forcedOffsetY != null
      ? forcedOffsetY
      : OffsetYInt === 0
      ? 0
      : OffsetYInt < 0
      ? -adjustedOffsetY
      : adjustedOffsetY;

  adjustedOffsetY =
    secondaryPosition === 'top'
      ? Math.min(adjustedOffsetY, 0)
      : secondaryPosition === 'bottom'
      ? Math.max(adjustedOffsetY, 0)
      : 0;

  adjustedOffsetX =
    secondaryPosition === 'left'
      ? Math.min(adjustedOffsetX, 0)
      : secondaryPosition === 'right'
      ? Math.max(adjustedOffsetX, 0)
      : 0;

  let distance = 6;

  if (borderColor != null) {
    distance = 7;
  }

  return (
    <>
      <div
        className={classNames('block-arrow', className)}
        style={{
          backgroundColor: pointerColor || background?.primaryColor,
          ...(borderColor != null
            ? {
                borderTop: `1px solid ${borderColor}`,
                borderLeft: `1px solid ${borderColor}`,
              }
            : {
                borderTop: `1px solid ${
                  pointerColor || background?.primaryColor
                }`,
                borderLeft: `1px solid ${
                  pointerColor || background?.primaryColor
                }`,
              }),
          ...(position === 'top'
            ? {
                top: `-${distance}px`,
                left: pokeSize?.width / 2 - 7 + adjustedOffsetX,
                transform: 'rotate(45deg)',
              }
            : position === 'bottom'
            ? {
                bottom: `-${distance}px`,
                left: pokeSize?.width / 2 - 7 + adjustedOffsetX,
                transform: 'rotate(225deg)',
              }
            : position === 'right'
            ? {
                right: `-${distance}px`,
                top: pokeSize?.height / 2 - 7 + adjustedOffsetY,
                transform: 'rotate(135deg)',
              }
            : position === 'left'
            ? {
                left: `-${distance}px`,
                top: pokeSize?.height / 2 - 7 + adjustedOffsetY,
                transform: 'rotate(-45deg)',
              }
            : {}),
        }}
      />
      <div
        className={classNames('block-arrow-border-blocker', {
          horizontal: position === 'top' || position === 'bottom',
          vertical: position === 'left' || position === 'right',
        })}
        style={{
          backgroundColor: pointerColor || background?.primaryColor,
          ...(position === 'top'
            ? {
                top: '-1px',
                left: pokeSize?.width / 2 - 5.8 + adjustedOffsetX - 2.8,
              }
            : position === 'bottom'
            ? {
                bottom: '-1px',
                left: pokeSize?.width / 2 - 5.8 + adjustedOffsetX - 2.8,
              }
            : position === 'left'
            ? {
                left: `-1px`,
                top: pokeSize?.height / 2 - 5.8 + adjustedOffsetY - 2.8,
              }
            : position === 'right'
            ? {
                right: `-1px`,
                top: pokeSize?.height / 2 - 5.8 + adjustedOffsetY - 2.8,
              }
            : {}),
        }}
      />
    </>
  );
};
