import {positionsArr} from '../BlockEditor/components/modals/Position';

export const getPokeWrapperStyle = (style = {}, useBackgroundColor = false) => {
  const {background, backgroundColor, shadow, borderRadius, borderColor} =
    style ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = color != null ? `${x}px ${y}px ${blur}px ${color}` : 'none';
  const {type, animated, primaryColor, secondaryColor} = background ?? {};
  const gradient = [primaryColor, secondaryColor];

  const pokeWrapperStyle = {
    ...(backgroundColor != null && useBackgroundColor === true
      ? {backgroundColor: backgroundColor}
      : type === 'color'
      ? {backgroundColor: primaryColor}
      : type === 'gradient'
      ? animated === true
        ? {
            backgroundColor: secondaryColor || '#fff',
          }
        : {
            backgroundImage: `linear-gradient(180deg, ${gradient[0]}, ${
              gradient[0].length > 7
                ? `${gradient[0].slice(0, 7)}80`
                : `${gradient[0]}}80)`
            }`,
            backgroundColor: gradient[1],
          }
      : {}),
    ...(borderColor != null ? {outline: `1px solid ${borderColor}`} : {}),
    boxShadow: boxShadow,
    borderRadius: `${borderRadius}px`,
  };

  return pokeWrapperStyle;
};

export const getHintPositionStyle = (poke, hintDimension, diffOsset = 0) => {
  const positionFlag = poke?.boostedPositionFlags;
  const positionItem = positionsArr.find((p) => p.value === positionFlag);
  const position = positionItem?.position;
  const borderRadius = poke?.style?.borderRadius;
  const hintOffset = poke?.style?.hintOffset || 0;
  const offsetBuilder = 4; // this offset is needed because of the clickable overlay
  const defaultHintDistance = 12;
  const pointerOffset = 16.970562748477143 / 2;

  // top positions
  if (position === 'top-left') {
    return {
      bottom: `-${
        hintDimension.height + offsetBuilder + defaultHintDistance + hintOffset
      }px`,
      left: `${borderRadius + pointerOffset - diffOsset}px`,
    };
  } else if (position === 'top') {
    return {
      bottom: `-${
        hintDimension.height + offsetBuilder + defaultHintDistance + hintOffset
      }px`,
      left: `calc(50% - ${hintDimension.width / 2}px)`,
    };
  } else if (position === 'top-right') {
    return {
      bottom: `-${
        hintDimension.height + offsetBuilder + defaultHintDistance + hintOffset
      }px`,
      right: `${borderRadius + pointerOffset - diffOsset}px`,
    };
  }
  // right positions
  else if (position === 'right-top') {
    return {
      top: `${borderRadius + pointerOffset - diffOsset}px`,
      left: `-${hintDimension.width + defaultHintDistance + hintOffset}px`,
    };
  } else if (position === 'right') {
    return {
      top: `calc(50% - ${hintDimension.height / 2}px)`,
      left: `-${hintDimension.width + defaultHintDistance + hintOffset}px`,
    };
  } else if (position === 'right-bottom') {
    return {
      bottom: `${borderRadius + pointerOffset - diffOsset}px`,
      left: `-${hintDimension.width + defaultHintDistance + hintOffset}px`,
    };
  }
  // bottom positions
  else if (position === 'bottom-right') {
    return {
      top: `-${
        hintDimension.height + offsetBuilder + defaultHintDistance + hintOffset
      }px`,
      right: `${borderRadius + pointerOffset - diffOsset}px`,
    };
  } else if (position === 'bottom') {
    return {
      top: `-${
        hintDimension.height + offsetBuilder + defaultHintDistance + hintOffset
      }px`,
      left: `calc(50% - ${hintDimension.width / 2}px)`,
    };
  } else if (position === 'bottom-left') {
    return {
      top: `-${
        hintDimension.height + offsetBuilder + defaultHintDistance + hintOffset
      }px`,
      left: `${borderRadius + pointerOffset - diffOsset}px`,
    };
  }
  // left positions
  else if (position === 'left-bottom') {
    return {
      bottom: `${borderRadius + pointerOffset - diffOsset}px`,
      right: `-${hintDimension.width + defaultHintDistance + hintOffset}px`,
    };
  } else if (position === 'left') {
    return {
      top: `calc(50% - ${hintDimension.height / 2}px)`,
      right: `-${hintDimension.width + defaultHintDistance + hintOffset}px`,
    };
  } else if (position === 'left-top') {
    return {
      top: `${borderRadius + pointerOffset - diffOsset}px`,
      right: `-${hintDimension.width + defaultHintDistance + hintOffset}px`,
    };
  }
};
export const getSnippetPositioningStyle = (positionFlag, zoom = 1) => {
  return {
    position: 'absolute',
    ...(positionFlag === 1
      ? {
          top: 16,
          left: 16,
          transformOrigin: 'top left',
        }
      : positionFlag === 2 // top center
      ? {
          top: 16,
          left: '50%',
          transform: `translateX(-50%) scale(${zoom})`,
          transformOrigin: 'top center',
        }
      : positionFlag === 4 // top right
      ? {
          top: 16,
          right: 16,
          transformOrigin: 'top right',
        }
      : positionFlag === 16 // bottom right
      ? {
          bottom: 16,
          right: 16,
          transformOrigin: 'bottom right',
        }
      : positionFlag === 32 // bottom center
      ? {
          bottom: 16,
          left: '50%',
          transform: `translateX(-50%) scale(${zoom})`,
          transformOrigin: 'bottom center',
        }
      : {
          bottom: 16,
          left: 16,
          transformOrigin: 'bottom left',
        }),
  };
};
