import {SegmentAttributesEditorContext} from 'components/SegmentAttributesEditor';
import {object} from 'prop-types';
import React, {createContext, useContext} from 'react';
import {
  CATEGORY_ACTIVITY,
  CATEGORY_CIO_EVENTS,
  CATEGORY_MIXPANEL_EVENTS,
  CATEGORY_POSTHOG_EVENTS,
  CATEGORY_SEGMENTIO_EVENTS,
  CATEGORY_TRACKED_EVENTS,
} from 'services/segment';
import './_Styles.scss';
import AttributeCategorySelect from './components/AttributeCategorySelect';
import AttributeEvolutionSelect from './components/AttributeEvolutionSelect';
import AttributeEvolutionStepSelect from './components/AttributeEvolutionStepSelect';
import AttributeEvolutionStepValue from './components/AttributeEvolutionStepValue';
import AttributeOperand, {OPERAND_OPTIONS} from './components/AttributeOperand';
import AttributePropertySelect, {
  EVENTS_PROPERTIES,
} from './components/AttributePropertySelect';
import AttributeTagsValue from './components/AttributeTagsValue';
import AttributeType from './components/AttributeType';
import AttributeValue from './components/AttributeValue';

const propTypes = {
  attribute: object.isRequired,
};

const defaultProps = {};

export const AttributeContext = createContext();

const Attribute = ({attribute}) => {
  const {attributes, setAttributes, onChange, handleDeleteAttributeFromLogic} =
    useContext(SegmentAttributesEditorContext);

  const handleDeleteAttribute = (uid) => {
    const attributesUpdated = attributes.filter((attr) => attr.uid !== uid);

    setAttributes(attributesUpdated);
    handleDeleteAttributeFromLogic(uid);
    if (onChange != null) {
      onChange(attributesUpdated);
    }
  };
  const handleUpdateAttribute = (uid, attrUpdated) => {
    const attributesUpdated = attributes.map((attr) =>
      attr.uid === uid ? attrUpdated : attr
    );

    setAttributes(attributesUpdated);
    if (onChange != null) {
      onChange(attributesUpdated);
    }
  };

  const commonProps = {
    key: attribute.uid,
    attribute: attribute,
    updateAttribute: (attr) =>
      handleUpdateAttribute(attribute.uid, {...attribute, ...attr}),
    onDelete: () => handleDeleteAttribute(attribute.uid),
  };

  const isPropertyBasic = Object.values(EVENTS_PROPERTIES)
    .map((p) => p.value)
    .includes(attribute.property);

  const isActivityAttribute = attribute.category === CATEGORY_ACTIVITY;
  const isEventAttribute = attribute.category === CATEGORY_TRACKED_EVENTS;
  const isSegmentioEventAttribute =
    attribute.category === CATEGORY_SEGMENTIO_EVENTS;
  const isCustomerioEventAttribute = attribute.category === CATEGORY_CIO_EVENTS;
  const isPosthogEventAttribute =
    attribute.category === CATEGORY_POSTHOG_EVENTS;
  const isMixpanelEventAttribute =
    attribute.category === CATEGORY_MIXPANEL_EVENTS;
  const isAnsweredAttribute = attribute.type === 'SURVEY_ANSWERED';

  const hasEvolutionSelect = isActivityAttribute === true;
  const hasTypeSelect =
    (isActivityAttribute === true &&
      attribute.type == null &&
      attribute.evolutionId === null) !== true;
  const hasPropertySelect =
    (isEventAttribute === true ||
      isSegmentioEventAttribute === true ||
      isCustomerioEventAttribute === true ||
      isPosthogEventAttribute === true ||
      isMixpanelEventAttribute === true) &&
    attribute.type !== null;
  const hasEvolutionStepSelect =
    attribute.type != null &&
    isActivityAttribute === true &&
    attribute.type !== 'ENGAGEMENT_RATE' &&
    isAnsweredAttribute === true;
  const hasEvolutionStepValue =
    attribute.type != null &&
    isActivityAttribute === true &&
    attribute.type !== 'ENGAGEMENT_RATE' &&
    isAnsweredAttribute === true;
  const hasOperand =
    attribute.type != null &&
    isActivityAttribute === false &&
    isPropertyBasic !== true;
  const hasValue = hasOperand === true && attribute.type !== 'TAGS';
  const hasPrimaryEventValue =
    (isEventAttribute === true || isSegmentioEventAttribute) &&
    [
      EVENTS_PROPERTIES.OCCURED_MORE_THAN.value,
      EVENTS_PROPERTIES.OCCURED_LESS_THAN.value,
    ].includes(attribute.property);

  const hasEventAdditionalProperty =
    (isEventAttribute === true ||
      isSegmentioEventAttribute === true ||
      isCustomerioEventAttribute === true ||
      isPosthogEventAttribute === true ||
      isMixpanelEventAttribute === true) &&
    attribute.property != null &&
    isPropertyBasic !== true;
  const hasEventValue = [
    EVENTS_PROPERTIES.OCCURED_MORE_THAN.value,
    EVENTS_PROPERTIES.OCCURED_LESS_THAN.value,
  ].includes(attribute.eventOccurrenceProperty);
  const hasEventOperand =
    (isEventAttribute === true ||
      isSegmentioEventAttribute === true ||
      isCustomerioEventAttribute === true ||
      isPosthogEventAttribute === true ||
      isMixpanelEventAttribute === true) &&
    attribute.type != null;
  const hasEventOccurrenceValue =
    isEventAttribute === true ||
    isSegmentioEventAttribute === true ||
    isCustomerioEventAttribute === true ||
    isPosthogEventAttribute === true ||
    isMixpanelEventAttribute === true;

  const hasTagsSelect =
    attribute.type === 'TAGS' &&
    [
      OPERAND_OPTIONS.OP_IS.value,
      OPERAND_OPTIONS.OP_NIS.value,
      OPERAND_OPTIONS.OP_CONTAINS.value,
      OPERAND_OPTIONS.OP_NCONTAINS.value,
    ].includes(attribute.op);

  return (
    <AttributeContext.Provider value={commonProps}>
      <div className="segment-attribute">
        <AttributeCategorySelect />
        {hasEvolutionSelect && <AttributeEvolutionSelect />}
        {hasTypeSelect && <AttributeType />}
        {hasPropertySelect && <AttributePropertySelect />}
        {hasEvolutionStepSelect && <AttributeEvolutionStepSelect />}
        {hasEvolutionStepValue && <AttributeEvolutionStepValue />}
        {hasPrimaryEventValue && (
          <AttributeValue type="number" label="times" event />
        )}
        {hasOperand && <AttributeOperand />}
        {hasValue && <AttributeValue />}

        {hasEventAdditionalProperty && (
          <AttributePropertySelect onlyBasic event />
        )}
        {hasEventValue && <AttributeValue type="number" label="times" event />}
        {hasEventOperand && <AttributeOperand event />}
        {hasEventOccurrenceValue && <AttributeValue eventOccurrence />}

        {hasTagsSelect && <AttributeTagsValue />}

        <div className="delete-attribute" onClick={commonProps.onDelete}>
          <i className="icon-close" />
        </div>
      </div>
    </AttributeContext.Provider>
  );
};

Attribute.propTypes = propTypes;
Attribute.defaultProps = defaultProps;

export default Attribute;
