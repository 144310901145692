import Axios from 'axios';
import {Environment} from 'conf/env';
import dayjs from 'dayjs';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {generalSelector} from 'selectors';
import {Swaler} from 'swaler';
import {
  F_ELIGIBLE_TRIAL_EXTENSION,
  F_GROWTH_TRIAL_USED,
  F_HAS_BEEN_EXTENDED,
  F_IS_OFFERED_BY_JIMO,
  F_IS_PAYING,
  F_IS_TRIALING,
  F_STARTUP_TRIAL_USED,
  PLAN_SCALE_ID,
  hasSaasMantraPlan,
} from './subscription';

const logger = new Swaler('project.service');

export const TRIGGER_DEFAULT = 'DEFAULT';
export const TRIGGER_CUSTOM = 'CUSTOM';
export const TRIGGER_BADGE = 'BADGE';
export const TRIGGER_SIDE_BADGE = 'SIDE_BADGE';
export const TRIGGER_NONE = 'NONE';
export const WIDGET_POSITION_BOTTOM_LEFT = 'BOTTOM_LEFT';
export const WIDGET_POSITION_BOTTOM_RIGHT = 'BOTTOM_RIGHT';
export const F_GET_STARTED_COMPLETED = 1;
export const F_GET_STARTED_INSTALL = 2;
export const F_GET_STARTED_INVITE_TEAM = 4;
export const F_GET_STARTED_CHECK_INTEGRATIONS = 8;
export const F_GET_STARTED_CREATE_ANNOUNCEMENT = 16;
export const F_GET_STARTED_CREATE_IDEATION = 32;
export const F_GET_STARTED_CREATE_PREVIEW = 64;
export const F_GET_STARTED_CUSTOMIZE_WIDGET = 128;
export const F_GET_STARTED_CUSTOMIZE_PORTAL = 256;
export const F_GET_STARTED_ENABLE_ROADMAP = 512;
export const PORTAL_STYLE_LIGHT = 'LIGHT';
export const PORTAL_STYLE_DARK = 'DARK';
export const PROJECT_ROLE_ADMIN = 'ADMIN';
export const PROJECT_ROLE_MEMBER = 'MEMBER';
export const PROJECT_ROLE_VIEWER = 'VIEWER';
export const PROJECT_ROLE_EDITOR = 'EDITOR';
export const PROJECT_ROLE_CUSTOM = 'CUSTOM';
export const F_PAGE_FEED = 1;
export const F_FEED_ANNOUNCEMENT = 2;
export const F_FEED_IDEATION = 4;
export const F_FEED_PREVIEW = 8;
export const F_FEED_SUBSCRIPTION_BOX = 16;
export const F_PAGE_ANNOUNCEMENT = 32; // deprecated
export const F_PAGE_FEEDBACK = 64;
export const F_FEEDBACK_REQUEST_BOX = 128;
export const F_FEEDBACK_PREVIEW = 256;
export const F_FEEDBACK_IDEATION = 512;
export const F_PAGE_ROADMAP = 1024;

export const F_EXTRA_WIDGET_COLLAPSE_POST = 1;
export const F_EXTRA_WIDGET_MINIMIZING_SIDE_TRIGGER = 2;
export const F_EXTRA_ROADMAP_SHOW = 4;
export const F_EXTRA_HAS_CRISP_WIDGET_SETUP = 8;
export const F_EXTRA_COMMENTS_DISABLE = 16;
export const F_EXTRA_DISABLE_TRIGGER_NOTIF_BADGE = 32;
export const F_EXTRA_COMMENTS_PRIVATE = 64;
export const F_EXTRA_ANONYMOUS_COMMENTS_DISABLED = 128;
export const F_EXTRA_LIKES_DISABLE = 256;
export const F_EXTRA_WIDGET_CLOSE_ON_OUTSIDE_CLICK = 512;
export const F_EXTRA_USAGE_IDENTIFY = 1024;
export const F_EXTRA_FIRST_POKE_ADOPTION = 2048;
export const F_EXTRA_FIRST_POKE_DISCOVERY = 4096;
export const F_EXTRA_FIRST_POST_PORTAL = 8192;
export const F_EXTRA_CAN_PUBLISH = 16384;
export const F_EXTRA_PORTAL_STANDALONE_ENABLED = 32768;
export const F_EXTRA_PORTAL_WIDGET_ENABLED = 65536;
export const F_EXTRA_WENT_LIVE = 131072;
export const F_EXTRA_ALLOW_SAME_DOMAIN_EMAIL_JOIN = 262144;
export const F_EXTRA_HAS_PUBLISHED_CHANGELOG = 1048576;
export const F_EXTRA_IS_FROZEN = 2097152;
export const F_EXTRA_IDENTIFY_VERIFICATION_ENABLED = 16777216;
export const F_EXTRA_DISABLE_RUN_JAVASCRIPT_TRIGGERS = 33554432;
export const F_EXTRA_DISABLE_FILLED_INPUT_TRIGGERS = 67108864;
export const F_EXTRA_CAN_SETUP_SEGMENT_INTEGRATION = 268435456;
export const F_EXTRA_REQUIRE_ONBOARDING = 536870912;
export const F_EXTRA_ONBOARDING_BOOKED = 1073741824;

export const F_USAGE_OTHER = 1;
export const F_USAGE_CHANGELOG = 2;
export const F_USAGE_DISCOVERY = 4;
export const F_USAGE_ROADMAP = 8;
export const F_USAGE_IN_APP_INTERACTION = 16;
export const F_USAGE_PRODUCT_TOUR = 32;
export const F_USAGE_PORTAL = 64;
export const F_USAGE_V2_PRODUCT_TOUR = 128;
export const F_USAGE_V2_IN_APP_ANNOUNCEMENT = 256;
export const F_USAGE_V2_TOOLTIPS = 512;
export const F_USAGE_V2_IN_APP_SURVEY = 1024;
export const F_USAGE_V2_PORTAL_NEWS_FEED = 2048;
export const F_USAGE_V2_WIDGET_NEWS_FEED = 4096;
export const F_USAGE_V2_PUBLIC_ROADMAP = 8192;
export const F_USAGE_V2_UP_VOTE = 16384;

export const defaultChangelogStyle = {
  tags: {borderRadius: 12},
  text: {
    bodyColor: '#000000',
    titleColor: '#000000',
    bodyFontColor: '#000000ff',
    bodyFontFamily: 'Roboto',
    titleFontColor: '#000000ff',
    titleFontFamily: 'Roboto',
  },
  cards: {
    shadow: {x: '0', y: '12', blur: '24', color: '#00000014'},
    background: {
      type: 'color',
      animated: false,
      primaryColor: '#FFFFFF',
      secondaryColor: '#FFFFFF',
    },
    borderRadius: 8,
    backgroundColor: '#ffffffff',
  },
  general: {
    background: {
      type: 'shape',
      shape: 'linear',
      animated: false,
      primaryColor: '#ffffffff',
      secondaryColor: '#486fffff',
    },
  },
  callToAction: {
    align: 'center',
    fontColor: '#ffffffff',
    fontFamily: 'Roboto',
    borderColor: '#ffffffff',
    borderRadius: 12,
    backgroundColor: '#486fffff',
  },
  trigger: {
    shadow: null,
    borderRadius: 50,
    backgroundColor: '#1f273d',
    fontColor: '#ffffffff',
    fontFamily: 'Inter',
    iconColor: '#ffffffff',
    iconName: null,
    iconSource: 'built-in',
  },
};

// Endpoints
const EP_PROJECT_CREATE = '/project';
const EP_PROJECT_GET = (projectId) => `/project/${projectId}`;
const EP_PROJECT_EMAILS_GET = (projectId) => `/project/${projectId}/emails`;
const EP_PROJECT_UPDATE = (projectId) => `/project/${projectId}`;
const EP_PROJECT_DELETE = (projectId) => `/project/${projectId}`;
const EP_PROJECT_MEMBER_PREFERENCE_UPT = (projectId) =>
  `/project/${projectId}/member-preference`;
const EP_PROJECT_MEMBER_INVITATION_CREATE = (projectId) =>
  `/project/${projectId}/member-invitation`;
const EP_PROJECT_MEMBER_GET = (projectId) => `/project/${projectId}/member`;
const EP_PROJECT_MEMBER_INVITATIONS_GET = (projectId) =>
  `/project/${projectId}/member-invitation`;
const EP_PROJECT_MEMBER_INVITATION_DELETE = (invitationId) =>
  `/project/member-invitation/${invitationId}`;
const EP_PROJECT_MEMBER_INVITATION_USE = (invitationId) =>
  `/project/member-invitation/${invitationId}/use`;
const EP_PROJECT_MEMBER_INVITATION_GET = (invitationCode) =>
  `/project/member-invitation/${invitationCode}`;
const EP_PROJECT_MEMBER_DELETE = (memberId) => `/project/member/${memberId}`;
const EP_PROJECT_MEMBER_GET_STARTED_UPT = (projectId) =>
  `/project/${projectId}/member/get-started`;
const EP_PROJECT_MEMBER_LAST_NOTIFICATION_OPENED_AT_UPT = (projectId) =>
  `/project/${projectId}/member/last-notification-opened-at`;
const EP_PROJECT_COUNT_HELD_FEEDBACKS = (projectId) =>
  `/project/${projectId}/held-feedbacks-count`;
const EP_PROJECT_PORTAL_LOGO_UPLOAD = (projectId) =>
  `/project/${projectId}/portal-logo`;
const EP_PROJECT_PORTAL_LOGO_DELETE = (projectId) =>
  `/project/${projectId}/portal-logo`;
const EP_PROJECT_MEMBER_UPDATE = (projectId, memberId) =>
  `/project/${projectId}/member/${memberId}`;
const EP_PROJECT_TO_JOIN_GET = '/project/to-join';
const EP_PROJECT_JOIN = (projectId) => `/project/${projectId}/join`;
const EP_PROJECT_CHURN_CREATE = (projectId) => `/project/${projectId}/churn`;
const EP_PROJECT_IDENTIFY_VERIFICATION_UPDATE = (projectId) =>
  `/project/${projectId}/identify-verification`;
const EP_PROJECT_IDENTIFY_TOKENS_GET = (projectId) =>
  `/project/${projectId}/identify-tokens`;

export const isPaying = (
  subscription = generalSelector.getProjectSubscription()
) => {
  if (subscription == null) {
    return false;
  }
  return hasFlags(
    [F_IS_PAYING, F_IS_OFFERED_BY_JIMO],
    subscription.extraFlags,
    true
  );
};
export const isTrying = (
  subscription = generalSelector.getProjectSubscription()
) => {
  if (subscription == null) {
    return false;
  }
  return hasFlag(F_IS_TRIALING, subscription.extraFlags);
};
export const canLaunchTrialExtension = (planId = null) => {
  const subscription = generalSelector.getProjectSubscription();
  const project = generalSelector.getProject();

  if (subscription == null) {
    return false;
  }
  if (hasSaasMantraPlan(project)) {
    return false;
  }
  if (planId === PLAN_SCALE_ID) {
    return false;
  }
  if (isPaying() === true) {
    return false;
  }
  if (
    hasFlags(
      [F_STARTUP_TRIAL_USED, F_GROWTH_TRIAL_USED],
      subscription.extraFlags,
      true
    ) === false
  ) {
    return false;
  }
  if (hasFlag(F_HAS_BEEN_EXTENDED, subscription.extraFlags)) {
    return false;
  }
  return hasFlag(F_ELIGIBLE_TRIAL_EXTENSION, subscription.extraFlags);
};
export const isEligibleToTrial = (planId = null) => {
  const subscription = generalSelector.getProjectSubscription();
  const project = generalSelector.getProject();

  if (subscription == null) {
    return false;
  }
  if (hasSaasMantraPlan(project)) {
    return false;
  }
  if (planId === PLAN_SCALE_ID) {
    return false;
  }
  if (isPaying() === true) {
    return false;
  }
  return (
    hasFlags(
      [F_STARTUP_TRIAL_USED, F_GROWTH_TRIAL_USED],
      subscription.extraFlags,
      true
    ) === false
  );
};

export const getDayToReset = () => {
  const project = generalSelector.getProject();

  return dayjs.unix(project.nextResetDay).diff(dayjs(), 'days');
};

export const getProjectPortalUrl = (project) => {
  return Environment.STORMWIND_URL.replace(
    '{projectDomain}',
    project.domainName
  );
};
export const calcPercentageActiveJimer = (project) => {
  return Math.round((project.activeJimers / project.thresholdJimers) * 100);
};
export const isValidName = (projectName) => {
  return projectName.length <= 32;
};

export const getProject = (projectId = generalSelector.getProject().uid) => {
  return Axios.get(EP_PROJECT_GET(projectId)).then((response) => response.data);
};

export const createProject = async (data) => {
  const {name, usage, requireOnboarding} = data;

  try {
    const project = await Axios.post(EP_PROJECT_CREATE, {
      name,
      usage,
      requireOnboarding,
    }).then((response) => response.data);

    logger.debug(`Project (${project.uid}) has been successfully created!`);
    return project;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateProject = async (projectId, project) => {
  const {
    name,
    triggerMode,
    widgetLanguage,
    widgetPosition,
    widgetTriggerMessage,
    portalLanguage,
    portalStyle,
    portalWelcomeTitle,
    portalWelcomeMessage,
    portalContentFlags,
    widgetThemeColor,
    widgetPrimaryColor,
    widgetContentFlags,
    widgetRoundness,
    widgetOffsets,
    domainName,
    extraFlags,
    whiteLabeling,
    digestEmailing,
    widgetSectionOrder,
    portalSectionOrder,
    rateLimitPokeAmount,
    rateLimitValue,
    rateLimitUnit,
    usageFlags,
    recentColors,
    changelogStyle,
  } = project;

  return Axios.put(EP_PROJECT_UPDATE(projectId), {
    name,
    triggerMode,
    widgetLanguage,
    widgetPosition,
    widgetTriggerMessage,
    portalLanguage,
    portalStyle,
    portalWelcomeTitle,
    portalWelcomeMessage,
    portalContentFlags,
    widgetThemeColor,
    widgetPrimaryColor,
    widgetContentFlags,
    widgetRoundness,
    widgetOffsets,
    domainName,
    extraFlags,
    whiteLabeling,
    digestEmailing,
    widgetSectionOrder,
    portalSectionOrder,
    rateLimitPokeAmount,
    rateLimitValue,
    rateLimitUnit,
    usageFlags,
    recentColors,
    changelogStyle,
  }).then((response) => response.data);
};

export const updateProjectIdentifyVerification = async (
  projectId,
  updateIdentifyVerificationProps
) => {
  const {isEnabled, shouldRegenerateKey} = updateIdentifyVerificationProps;
  return Axios.put(EP_PROJECT_IDENTIFY_VERIFICATION_UPDATE(projectId), {
    isEnabled,
    shouldRegenerateKey,
  }).then((response) => response.data);
};

export const updateProjectMember = async (projectId, memberId, data) => {
  const {role, customRole} = data;

  return Axios.put(EP_PROJECT_MEMBER_UPDATE(projectId, memberId), {
    role,
    customRole,
  }).then((response) => response.data);
};

export const updateProjectMemberPreference = async (projectId, preference) => {
  const {notificationOnComment, notificationOnRequest, weeklyEngagementReport} =
    preference;

  return Axios.put(EP_PROJECT_MEMBER_PREFERENCE_UPT(projectId), {
    notificationOnComment,
    notificationOnRequest,
    weeklyEngagementReport,
  }).then((response) => response.data);
};

export const inviteMember = async (data) => {
  const {email, role, customRole} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_PROJECT_MEMBER_INVITATION_CREATE(projectId), {
    email,
    role,
    customRole,
  }).then((response) => response.data);
};

export const getProjectMembers = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_PROJECT_MEMBER_GET(projectId)).then(
    (response) => response.data
  );
};

export const getProjectMemberInvitations = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_PROJECT_MEMBER_INVITATIONS_GET(projectId)).then(
    (response) => response.data
  );
};

export const deleteProject = (projectId = generalSelector.getProject().uid) => {
  return Axios.delete(EP_PROJECT_DELETE(projectId)).then(
    (response) => response.data
  );
};

export const deleteProjectMemberInvitation = (data) => {
  const {invitationId} = data;

  return Axios.delete(EP_PROJECT_MEMBER_INVITATION_DELETE(invitationId)).then(
    (response) => response.data
  );
};

export const useProjectMemberInvitation = (data) => {
  const {invitationId} = data;

  return Axios.post(EP_PROJECT_MEMBER_INVITATION_USE(invitationId)).then(
    (response) => response.data
  );
};

export const getProjectMemberInvitation = (data) => {
  const {invitationCode} = data;

  return Axios.get(EP_PROJECT_MEMBER_INVITATION_GET(invitationCode))
    .then((response) => response.data)
    .then((invitation) =>
      invitation.length != null && invitation.length === 0 ? null : invitation
    );
};

export const deleteProjectMember = (data) => {
  const {memberId} = data;

  return Axios.delete(EP_PROJECT_MEMBER_DELETE(memberId)).then(
    (response) => response.data
  );
};

export const updateGetStartedFlag = ({flag, skipFlag}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.put(EP_PROJECT_MEMBER_GET_STARTED_UPT(projectId), {
    flag,
    skipFlag,
  }).then((response) => response.data);
};

export const updateLastNotificationOpenedAt = ({lastNotificationOpenedAt}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.put(
    EP_PROJECT_MEMBER_LAST_NOTIFICATION_OPENED_AT_UPT(projectId),
    {
      lastNotificationOpenedAt,
    }
  ).then((response) => response.data);
};

export const countProjectHeldFeedbacks = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_PROJECT_COUNT_HELD_FEEDBACKS(projectId)).then(
    (response) => response.data
  );
};

export const uploadPortalLogo = (data) => {
  const projectId = generalSelector.getProject().uid;
  const {file} = data;
  const formData = new FormData();

  formData.append('file', file);
  return Axios.post(EP_PROJECT_PORTAL_LOGO_UPLOAD(projectId), formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  }).then((response) => response.data);
};

export const deletePortalLogo = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.delete(EP_PROJECT_PORTAL_LOGO_DELETE(projectId)).then(
    (response) => response.data
  );
};

export const getProjectsToJoin = () => {
  return Axios.get(EP_PROJECT_TO_JOIN_GET).then((response) => response.data);
};

export const joinProject = (projectId) => {
  return Axios.post(EP_PROJECT_JOIN(projectId)).then(
    (response) => response.data
  );
};

export const createChurn = (data) => {
  const projectId = generalSelector.getProject().uid;
  const {reason, details, isTrial} = data;

  return Axios.post(EP_PROJECT_CHURN_CREATE(projectId), {
    reason,
    details,
    isTrial,
  });
};

export const fetchIdentifyTokens = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_PROJECT_IDENTIFY_TOKENS_GET(projectId)).then(
    (response) => response.data
  );
};
export const fetchProjectEmails = (
  projectId = generalSelector.getProject().uid
) => {
  return Axios.get(EP_PROJECT_EMAILS_GET(projectId)).then(
    (response) => response.data
  );
};
