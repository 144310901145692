import React from 'react';

import Button from 'components/Button';
import {
  PermissionsEvent,
  PermissionsSuccessTracker,
} from 'constants/permissions';
import {crispHelpers} from 'helpers';
import {hasPermissions} from 'helpers/permission';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_EVENTS_WITH_ID} from 'router/routes.const';
import {dataSelector, generalSelector} from 'selectors';
import {trackerService} from 'services';
import {
  PLAN_BASIC_ID,
  PLAN_GROWTH_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';
import ModalCreateTracker from '../ModalCreateTracker';
import './_Styles.scss';

const SuccessTrackerHeader = ({
  defaultTrackerType,
  showCreateModal,
  setShowCreateModal,
}) => {
  const history = useHistory();
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectSubscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const plan = plans.find((p) => p.uid === projectSubscription.plan);

  const canCreateTracker = hasPermissions(
    PermissionsSuccessTracker.CREATE_EDIT
  );
  const canCreateEvent = hasPermissions(PermissionsEvent.CREATE_EDIT);

  const {isLoading, data: trackers} = useQuery({
    queryKey: 'trackersUsage',
    queryFn: () =>
      trackerService
        .getTrackers()
        .then((trackers) =>
          trackers.filter((tracker) =>
            [
              TRACKER_TYPE_ACTIVATION_TRACKER,
              TRACKER_TYPE_FEATURE_USAGE,
            ].includes(tracker.type)
          )
        ),
    initialData: [],
  });

  return (
    <div className="success-tracker-header">
      <div className="title-3">Success Tracker</div>
      <div className="actions-wrapper">
        {canCreateEvent && (
          <Button
            className="new-event-btn"
            iconLeft="icon-plus"
            onClick={() => history.push(ROUTE_EVENTS_WITH_ID('new'))}>
            Add event
          </Button>
        )}
        {canCreateTracker && (
          <div className="btns-wrapper">
            <Button
              reverted
              primary
              className="new-tracker-btn"
              iconLeft="icon-plus"
              disabled={isLoading}
              onClick={() => {
                if (
                  trackers?.length < project.thresholdTrackers ||
                  project.thresholdTrackers === -1
                ) {
                  return setShowCreateModal(true);
                }
                if (
                  project.thresholdTrackers === plan.trackers &&
                  [PLAN_BASIC_ID, PLAN_STARTUP_ID].includes(
                    projectSubscription.plan
                  )
                ) {
                  return update({
                    planId: PLAN_GROWTH_ID,
                    title: 'Need more trackers?',
                    description: 'Upgrade and create up to 5 environments!',
                  });
                }
                if (trackers.length >= project.thresholdTrackers) {
                  return crispHelpers.startCrispThread(
                    "I'd like to increase my trackers limit!"
                  );
                }
              }}>
              New tracker
            </Button>
          </div>
        )}
      </div>

      {showCreateModal === true && (
        <ModalCreateTracker
          isOpen={showCreateModal}
          onRequestClose={() => setShowCreateModal(false)}
          defaultTrackerType={defaultTrackerType}
        />
      )}
    </div>
  );
};

export default SuccessTrackerHeader;
