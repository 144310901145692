import {
  CATEGORY_ACTIVITY,
  CATEGORY_ATTRIBUTES,
  CATEGORY_CIO_EVENTS,
  CATEGORY_CUSTOM_ATTRIBUTES,
  CATEGORY_MIXPANEL_EVENTS,
  CATEGORY_POSTHOG_EVENTS,
  CATEGORY_SEGMENTIO_EVENTS,
  CATEGORY_SEGMENTIO_FIELDS,
  CATEGORY_TRACKED_EVENTS,
} from 'services/segment';
import {v4 as uuidv4} from 'uuid';
import {OPERAND_OPTIONS} from './components/Attribute/components/AttributeOperand';
import {EVENTS_PROPERTIES} from './components/Attribute/components/AttributePropertySelect';
import {ATTRIBUTES} from './select-attribute';

export const defaultBlocks = [
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Id',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-string" />,
    value: 'ID',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Name',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-string" />,
    value: 'NAME',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Email',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-at" />,
    value: 'EMAIL',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Active',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-vote-opinion" />,
    value: 'ACTIVE',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Last activity',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-calendar" />,
    value: 'LAST_ACTIVITY_AT',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Created',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-calendar" />,
    value: 'CREATED',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Sessions',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-play-rounded" />,
    value: 'SESSIONS',
  },
  {
    category: CATEGORY_ATTRIBUTES,
    title: 'Tags',
    iconBackgroundColor: '#FFE7A4',
    icon: <i className="icon-pin" />,
    value: 'TAGS',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Has been seen',
    iconBackgroundColor: '#BCDDFF',
    icon: <i className="icon-eye" />,
    value: 'SAW',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Has not been seen',
    iconBackgroundColor: '#BCDDFF',
    icon: <i className="icon-not-seen" />,
    value: 'NSAW',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Has been completed',
    iconBackgroundColor: '#BCDDFF',
    icon: <i className="icon-answered" />,
    value: 'SURVEY_COMPLETED',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Has not been completed',
    iconBackgroundColor: '#BCDDFF',
    icon: <i className="icon-not-completed" />,
    value: 'SURVEY_NCOMPLETED',
  },
  {
    category: CATEGORY_ACTIVITY,
    title: 'Answered with',
    iconBackgroundColor: '#BCDDFF',
    icon: <i className="icon-interaction" />,
    value: 'SURVEY_ANSWERED',
  },
];

export const buildLogic = (segment = {}) => {
  const {logic = null, attributes = []} = segment || {};

  if (logic == null) {
    return {
      uid: uuidv4(),
      type: 'logicGroup',
      operator: 'and',
      children: attributes.map((attr) => ({
        type: 'condition',
        attributeId: attr.uid,
      })),
    };
  } else {
    // remove missing attributes from logic
    const removeMissingAttributesFromLogic = (logic) => {
      logic.children = logic.children.filter((child) => {
        if (child.type === 'condition') {
          return attributes.find((attr) => attr.uid === child.attributeId);
        } else if (child.type === 'logicGroup') {
          removeMissingAttributesFromLogic(child);
          return true;
        }
        return true;
      });
    };

    removeMissingAttributesFromLogic(logic);
    return logic;
  }
};

export const addAttributeToLogic = (logic, attribute, groupId) => {
  // search through logic object to find the group with the given id and add the attribute to it
  const addAttributeToGroup = (group) => {
    if (group.uid === groupId) {
      group.children.push({
        type: 'condition',
        attributeId: attribute.uid,
      });
    } else {
      group.children.forEach((child) => {
        if (child.type === 'logicGroup') {
          addAttributeToGroup(child);
        }
      });
    }
  };

  addAttributeToGroup(logic);

  return logic;
};

export const addLogicGroupToLogic = (logic, groupId) => {
  // search through logic object to find the group with the given id and add the attribute to it
  const addLogicGroupToGroup = (group) => {
    if (group.uid === groupId) {
      group.children.push({
        uid: uuidv4(),
        type: 'logicGroup',
        operator: group.operator === 'and' ? 'or' : 'and',
        children: [],
      });
    } else {
      group.children.forEach((child) => {
        if (child.type === 'logicGroup') {
          addLogicGroupToGroup(child);
        }
      });
    }
  };

  addLogicGroupToGroup(logic);
  return logic;
};

export const deleteAttributeFromLogic = (logic, attributeId) => {
  const deleteAttributeFromGroup = (group) => {
    group.children = group.children.filter((child) => {
      if (child.type === 'condition') {
        return child.attributeId !== attributeId;
      } else if (child.type === 'logicGroup') {
        deleteAttributeFromGroup(child);
        return true;
      }
      return true;
    });
  };

  deleteAttributeFromGroup(logic);
  return logic;
};

export const deleteLogicGroupFromLogic = (logic, groupId) => {
  const deleteLogicGroupFromGroup = (group) => {
    group.children = group.children.filter((child) => {
      if (child.type === 'logicGroup') {
        if (child.uid === groupId) {
          return false;
        } else {
          deleteLogicGroupFromGroup(child);
          return true;
        }
      }
      return true;
    });
  };

  deleteLogicGroupFromGroup(logic);
  return logic;
};

export const updateLogicOperator = (logic, groupId, operator) => {
  const updateOperatorFromGroup = (group) => {
    if (group.uid === groupId) {
      group.operator = operator;
    } else {
      group.children.forEach((child) => {
        if (child.type === 'logicGroup') {
          updateOperatorFromGroup(child);
        }
      });
    }
  };

  updateOperatorFromGroup(logic);
  return logic;
};

export const createAttribute = (
  type = '',
  {eventId = null, customAttributeId = null, trackerStepId = null} = {}
) => {
  const category = getAttributeCategory(type);
  const attribute = {
    uid: uuidv4(),
    category,
    type: getAttributeType(type),
    property: getDefaultProperty(category),
    value: type.startsWith('CUSTOM_ATTRIBUTES-') ? type.substring(18) : null,
    name: type.startsWith('SEGMENTIO_')
      ? type.substring(17)
      : type.startsWith('CIO_EVENTS')
      ? type.substring(11)
      : type.startsWith('POSTHOG_EVENTS')
      ? type.substring(15)
      : type.startsWith('MIXPANEL_EVENTS')
      ? type.substring(16)
      : null,
    eventId: eventId,
    op: null,
    values: getDefaultValues(type),
    customType: null,
    evolutionId: null,
    stepId: null,
    customAttributeId: customAttributeId,
    eventOccurrenceProperty: null,
    eventOccurrenceOp: null,
    eventOccurrenceValues: [],
    trackerStepId: trackerStepId,
  };

  return attribute;
};

export const getDefaultProperty = (category = '') => {
  switch (category) {
    case CATEGORY_SEGMENTIO_EVENTS: {
      return EVENTS_PROPERTIES.HAS_OCCURRED.value;
    }
    case CATEGORY_TRACKED_EVENTS: {
      return EVENTS_PROPERTIES.HAS_OCCURRED.value;
    }
    default: {
      return null;
    }
  }
};

const getDefaultValues = (type = '') => {
  switch (type) {
    case ATTRIBUTES.ATTR_ACTIVE.value: {
      return [''];
    }
    case ATTRIBUTES.ATTR_LAST_ACTIVITY.value: {
      return [new Date()];
    }
    case ATTRIBUTES.ATTR_CREATED.value: {
      return [new Date()];
    }
    default: {
      return [''];
    }
  }
};

const attributesValueArr = defaultBlocks
  .filter((b) => b.category === CATEGORY_ATTRIBUTES)
  .map((b) => b.value);
const activityValueArr = defaultBlocks
  .filter((b) => b.category === CATEGORY_ACTIVITY)
  .map((b) => b.value);

export const getAttributeType = (type = '') => {
  return type?.startsWith('SEGMENTIO_FIELDS-')
    ? 'SEGMENTIO_FIELDS'
    : type?.startsWith('SEGMENTIO_EVENTS-')
    ? 'SEGMENTIO_EVENTS'
    : type.startsWith('CIO_EVENTS-')
    ? 'CIO_EVENTS'
    : type.startsWith('POSTHOG_EVENTS-')
    ? 'POSTHOG_EVENTS'
    : type.startsWith('MIXPANEL_EVENTS-')
    ? 'MIXPANEL_EVENTS'
    : type.startsWith('TRACKED_EVENTS-')
    ? 'TRACKED_EVENTS'
    : type?.startsWith('CUSTOM_ATTRIBUTES-')
    ? 'CUSTOM'
    : attributesValueArr.includes(type)
    ? type
    : activityValueArr.includes(type)
    ? type
    : null;
};

export const getAttributeCategory = (type = '') => {
  return type?.startsWith('SEGMENTIO_FIELDS-')
    ? CATEGORY_SEGMENTIO_FIELDS
    : type?.startsWith('SEGMENTIO_EVENTS-')
    ? CATEGORY_SEGMENTIO_EVENTS
    : type.startsWith('CIO_EVENTS-')
    ? CATEGORY_CIO_EVENTS
    : type.startsWith('POSTHOG_EVENTS-')
    ? CATEGORY_POSTHOG_EVENTS
    : type.startsWith('MIXPANEL_EVENTS-')
    ? CATEGORY_MIXPANEL_EVENTS
    : type.startsWith('TRACKED_EVENTS-')
    ? CATEGORY_TRACKED_EVENTS
    : type?.startsWith('CUSTOM_ATTRIBUTES-')
    ? CATEGORY_CUSTOM_ATTRIBUTES
    : attributesValueArr.includes(type)
    ? CATEGORY_ATTRIBUTES
    : activityValueArr.includes(type)
    ? CATEGORY_ACTIVITY
    : null;
};

export const isSegmentationValid = (attributes = [], logic) => {
  if (logic == null) {
    return {isValid: false, issues: ['Empty logic']};
  }

  let isValid = true;
  const issues = [];

  const checkAttributesFromLogic = (logic) => {
    if (!(logic.children?.length > 0)) {
      isValid = false;
      issues.push(['Empty logic group']);
    }
    logic.children.forEach((child) => {
      if (child.type === 'condition') {
        const attribute = attributes.find(
          (attr) => attr.uid === child.attributeId
        );
        if (attribute == null) {
          isValid = false;
          issues.push([`Attribute ${child.attributeId} is missing`]);
        }
      } else if (child.type === 'logicGroup') {
        checkAttributesFromLogic(child);
      }
    });
  };

  checkAttributesFromLogic(logic);

  for (const attribute of attributes) {
    if (attribute.type == null) {
      isValid = false;
    }
    if (
      [
        'SEGMENTIO_FIELDS',
        'SEGMENTIO_EVENTS',
        'TRACKED_EVENTS',
        'POSTHOG_EVENTS',
        'MIXPANEL_EVENTS',
      ].includes(attribute.type)
    ) {
      if (
        [
          'SEGMENTIO_FIELDS',
          'SEGMENTIO_EVENTS',
          'POSTHOG_EVENTS',
          'MIXPANEL_EVENTS',
        ].includes(attribute.type)
      ) {
        if (attribute.name == null) {
          isValid = false;
        }
      } else if (attribute.type === 'TRACKED_EVENTS') {
        if (attribute.eventId == null) {
          isValid = false;
        }
      }

      if (
        [
          'SEGMENTIO_EVENTS',
          'TRACKED_EVENTS',
          'POSTHOG_EVENTS',
          'MIXPANEL_EVENTS',
        ].includes(attribute.type)
      ) {
        if (attribute.property == null) {
          isValid = false;
        }
        if (
          attribute.property != null &&
          Object.values(EVENTS_PROPERTIES)
            .map((p) => p.value)
            .includes(attribute.property) !== true &&
          attribute.eventProperty == null
        ) {
          isValid = false;
        }
        if (attribute.eventOp == null) {
          isValid = false;
        }
        if (
          [
            OPERAND_OPTIONS.OP_EVENT_IN_THE_LAST.value,
            OPERAND_OPTIONS.OP_EVENT_SINCE.value,
          ].includes(attribute.eventOp)
        ) {
          if (!attribute.eventOccurrenceValues[0]) {
            isValid = false;
          }
        }
        if (
          [
            EVENTS_PROPERTIES.OCCURED_MORE_THAN.value,
            EVENTS_PROPERTIES.OCCURED_LESS_THAN.value,
          ].includes(attribute.property) ||
          [
            EVENTS_PROPERTIES.OCCURED_MORE_THAN.value,
            EVENTS_PROPERTIES.OCCURED_LESS_THAN.value,
          ].includes(attribute.eventProperty)
        ) {
          if (attribute.eventValues[0] == null) {
            isValid = false;
          }
        }
      }
    }

    if (
      [
        'NAME',
        'EMAIL',
        'ACTIVE',
        'LAST_ACTIVITY_AT',
        'CREATED',
        'SESSIONS',
      ].includes(attribute.type)
    ) {
      const [operand] = attribute.op?.split(';') || [];

      if (operand == null) {
        isValid = false;
      }
      if (
        [
          OPERAND_OPTIONS.OP_IS_EMPTY.value,
          OPERAND_OPTIONS.OP_NIS_EMPTY.value,
          OPERAND_OPTIONS.OP_IS_ACTIVE.value,
          OPERAND_OPTIONS.OP_NIS_ACTIVE.value,
        ].includes(operand) !== true
      ) {
        if (attribute.values[0] == null || attribute.values[0] === '') {
          isValid = false;
        }
      }
      if (
        [
          OPERAND_OPTIONS.OP_IS_BETWEEN.value,
          OPERAND_OPTIONS.OP_IS_NBETWEEN.value,
        ].includes(operand)
      ) {
        if (attribute.values[1] == null || attribute.values[1] === '') {
          isValid = false;
        }
      }
    }

    if (attribute.type === 'CUSTOM') {
      const [operand] = attribute.op?.split(';') || [];

      if (attribute.customAttributeId == null) {
        isValid = false;
      }
      if (attribute.op == null) {
        isValid = false;
      }
      if (
        [
          OPERAND_OPTIONS.OP_IS_EMPTY.value,
          OPERAND_OPTIONS.OP_NIS_EMPTY.value,
        ].includes(operand) !== true
      ) {
        if (attribute.values[0] == null || attribute.values[0] === '') {
          isValid = false;
        }
      }
    }

    if (
      [
        'SAW',
        'NSAW',
        'SURVEY_COMPLETED',
        'SURVEY_NCOMPLETED',
        'SURVEY_ANSWERED',
      ].includes(attribute.type)
    ) {
      if (attribute.evolutionId == null) {
        isValid = false;
      }
      if (attribute.type === 'SURVEY_ANSWERED') {
        if (attribute.stepId == null) {
          isValid = false;
        }
        if (attribute.op == null) {
          isValid = false;
        }
        if (attribute.values[0] == null || attribute.values[0] === '') {
          isValid = false;
        }
      }
    }

    if (attribute.type === 'TAGS') {
      if (attribute.op == null) {
        isValid = false;
      }
      if (
        [
          OPERAND_OPTIONS.OP_IS.value,
          OPERAND_OPTIONS.OP_NIS.value,
          OPERAND_OPTIONS.OP_CONTAINS.value,
          OPERAND_OPTIONS.OP_NCONTAINS.value,
        ].includes(attribute.op) === true &&
        attribute.values.length === 0
      ) {
        isValid = false;
      }
    }
  }

  return {isValid, issues};
};
